import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import RollingBannerSection from './RollingBannerSection';
import './Footerbar.css';

const FooterBar = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            firstName: formData.name, // using firstName field for consistency
            email: formData.email,
            phone: formData.phone,
        };

        emailjs.send('service_gvt5vwp', 'template_nwga3kh', templateParams, 'OpJMLUp7AgSF4zW4L')
            .then((response) => {
                alert('Sign up successful!');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                });
            }, (error) => {
                alert('There was an error signing up.');
                console.error('Failed to send email:', error);
            });
    };

    return (
        <div className="footer-bar">
            <RollingBannerSection />
            <div className="footer-images">
                <img src="../assets/Gymfloor1.webp" alt="1" />
                <img src="../assets/Workouts.webp" alt="2" />
                <img src="../assets/ergonometer1.webp" alt="3" />
                <img src="../assets/Group train.webp" alt="4" />
                <img src="../assets/dumbbells.webp" alt="5" />
                <img src="../assets/Pinloaded.webp" alt="6" />
            </div>
            <div className="stay-updated">
                <div>

                 
      <h2>STAY UPDATED</h2>
                    <p>Sign up to be the first to find out when we have new services and special offers.</p>
                    <p>We respect your privacy and will never share your information with any third-party vendors.</p>
                    <br></br>

                       <h3>As well as being near to major public transport, we have plentiful on-street and a large dedicated onsite free parking area with many spaces for you (entry in back via Smallwood Av. / Public Ln.) for your easy access. The Ramp Physio and Fitness team help treat locals for gym, personal training and physiotherapy from across Sydney, the inner west and nearby suburbs</h3>
                       <br></br>

                       <div>
                            <p>
                                <a href="/news/homebushphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}>Homebush</a> | 
                                <a href="/news/homebushwestphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Homebush West</a> |
                                <a href="/news/flemingtonphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Flemington</a> |
                                <a href="/news/northstrathfieldphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> North Strathfield</a> |
                                <a href="/news/newingtonphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Newington</a> |
                                <a href="/news/silverwaterphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Silverwater</a> |
                                <a href="/news/lidcombephysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Lidcombe</a> |
                                <a href="/news/concordphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Concord</a> |
                                <a href="/news/concordwestphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Concord West</a> |
                                <a href="/news/strathfieldphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Strathfield</a> |
                                <a href="/news/rhodesphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Rhodes</a> |
                                <a href="/news/rosehillphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Rosehill</a> |
                                <a href="/news/parramattaphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Parramatta</a> |
                                <a href="/news/westrydephysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> West Ryde</a> |
                                <a href="/news/burwoodphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Burwood</a> |
                                <a href="/news/fivedockphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Five Dock</a> |
                                <a href="/news/ashfieldphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Ashfield</a> |
                                <a href="/news/croydonparkphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Croydon Park</a> |
                                <a href="/news/strathfieldsouthphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Strathfield South</a> |
                                <a href="/news/greenacrephysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Greenacre</a> |
                                <a href="/news/chulloraphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Chullora</a> |
                                <a href="/news/sydneyolympicparkphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Sydney Olympic Park</a> |
                                <a href="/news/auburnphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Auburn</a> |
                                <a href="/news/ermingtonphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Ermington</a> |
                                <a href="/news/libertygrovephysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Liberty Grove</a> |
                                <a href="/news/cabritaphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Cabrita</a> |
                                <a href="/news/belfieldphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Belfield</a> |
                                <a href="/news/enfieldphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Enfield</a> |
                                <a href="/news/campsiephysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Campsie</a> |
                                <a href="/news/yagoonaphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Yagoona</a> |
                                <a href="/news/bankstownphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Bankstown</a> |
                                <a href="/news/lakembaphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Lakemba</a> |
                                <a href="/news/rydalmerephysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Rydalmere</a> |
                                <a href="/news/camelliaphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Camellia</a> |
                                <a href="/news/canadabayphysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Canada Bay</a> |
                                <a href="/news/clydephysiotherapy" style={{ textDecoration: 'none', color: 'inherit' }}> Clyde</a>
                            </p>
                        </div>


                     <br></br>
                </div>
                <form className="signup-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="tel"
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit">Sign Up</button>
                </form>
            </div>
            <div className="separator-line"></div>
            <div className="footer-info">
                <div className="support">
                    <h3>SUPPORT</h3>
                    <ul>
                        <li><a href="/termsandconditions">Terms and Conditions</a></li>
                        <li><a href="/privacypolicy">Privacy Policy</a></li>
                        <li><a href="/faq">FAQs</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/team">Team</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/news">News</a></li>
                        <li><a href="/NDIS">NDIS</a></li>
                    </ul>
                </div>
                <div className="logo">
                    <img src="../assets/ramp-icon-update.png" alt="Logo" />
                </div>
                <div className="contact">
                  <h3>CONTACT</h3>
                    <p>208 Parramatta Rd, Homebush</p>
                    {/* Wrap the email in an <a> with mailto: and conversion tracking */}
                    <p>
                      <a
                        href="mailto:info@rampphysioandfitness.com.au"
                        className="footer-link"
                        onClick={() =>
                          window.gtag_report_conversion('mailto:info@rampphysioandfitness.com.au')
                        }
                      >
                        info@rampphysioandfitness.com.au
                      </a>
                    </p>
                    {/* Wrap the phone number in an <a> with tel: and conversion tracking */}
                    <p>
                      <a
                        href="tel:+61281881618"
                        className="footer-link"
                        onClick={() => window.gtag_report_conversion('tel:+61281881618')}
                      >
                        (02) 8188 1618
                      </a>
                    </p>
                    <br />
                    <p><strong>Same Day Appointments Guaranteed</strong></p>
                    <p><strong>Plentiful Free Onsite Parking</strong></p>
                    <br></br>
                    <div className="book-container">
                        <a href="https://ramp-physio-and-fitness.au4.cliniko.com/bookings#service"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="book-footer">
                            Book Now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterBar;
